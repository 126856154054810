import { useLocation } from "react-router-dom";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { useState } from "react";
import { logo } from "../assets";
import {navigation} from "../constants";
import Button from "./Button";
import MenuSvg from "../assets/svg/MenuSvg";
import { HamburgerMenu } from "./design/Header";
import ThemeToggle from "./ThemeToggle";

const Header = () => {
  const pathname = useLocation();
  const [openNavigation, setOpenNavigation] = useState(false);

  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false);
      enablePageScroll();
    } else {
      setOpenNavigation(true);
      disablePageScroll();
    }
  };

  const handleClick = () => {
    if (!openNavigation) return;

    enablePageScroll();
    setOpenNavigation(false);
  };

  return (
      <div
          className={`fixed top-0 left-0 w-full z-50 border-b border-n-6 dark:border-n-8 ${
              openNavigation
                  ? "bg-white dark:bg-n-8"
                  : "bg-white/80 dark:bg-n-8/90 backdrop-blur-md"
          } transition-all duration-300 ease-in-out`}
      >
        <div className="flex items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-4">
          <a className="block w-[12rem] xl:mr-8" href="/">
            <img src={logo} width={190} height={40} alt="Automated AI" />
          </a>

          <nav
              className={`${
                  openNavigation ? "flex" : "hidden"
              } fixed top-[5rem] left-0 right-0 bottom-0 bg-white dark:bg-n-8 lg:static lg:flex lg:mx-auto lg:bg-transparent transition-all duration-300`}
          >
            <div className="relative z-2 flex flex-col items-center justify-center m-auto lg:flex-row">
              {navigation.map((item) => (

                      // Regular navigation items
                      <a
                          key={item.id}
                          href={item.url}
                          onClick={handleClick}
                          className={`block relative font-code text-2xl uppercase text-n-8 dark:text-n-1 transition-all duration-300 hover:text-color-1 ${
                              item.onlyMobile ? "lg:hidden" : ""
                          } px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold ${
                              item.url === pathname.hash
                                  ? "z-2 lg:text-n-8 dark:lg:text-n-1"
                                  : "lg:text-n-8/60 dark:lg:text-n-1/60"
                          } lg:leading-5 lg:hover:text-n-8 dark:lg:hover:text-n-1 xl:px-12`}
                      >
                        {item.title}
                      </a>

              ))}
            </div>

            <HamburgerMenu />
          </nav>

          <div className="ml-auto flex items-center gap-4">
            <ThemeToggle />

            <Button className="hidden lg:flex" href="#login">
              Sign in
            </Button>

            <Button
                className="lg:hidden"
                px="px-3"
                onClick={toggleNavigation}
            >
              <MenuSvg openNavigation={openNavigation} />
            </Button>
          </div>
        </div>
      </div>
  );
};

export default Header;