import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import ScrollToTop from "./ScrollToTop";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {/* <ScrollToTop /> */}
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
