import {
  benefitIcon1,
  benefitIcon2,
  benefitIcon3,
  benefitIcon4,
  benefitImage2,
  benefitCard1,
  benefitCard2,
  benefitCard3,
  benefitCard4,
  benefitCard5,
  benefitCard6,
  chromecast,
  disc02,
  discord,
  discordBlack,
  facebook,
  figma,
  file02,
  framer,
  homeSmile,
  instagram,
  notification2,
  notification3,
  notification4,
  notion,
  photoshop,
  plusSquare,
  protopie,
  raindrop,
  recording01,
  recording03,
  security,
  insights,
  efficiency,
  growth,
  searchMd,
  slack,
  sliders04,
  telegram,
  twitter,
  vitf,
  gre8i,
  empowering,
  innovate,
  autonomous,
  government,
  opexcellence,
  infrastructure,
  secure,
  global,
  benefitImage3,
  benefitImage4,
  benefitImage5,
  benefitImage8,
  benefitImage7,
  benefitImage9,
  benefitImage6,
  benefitImage10,
  benefitImage11,
  benefitImage12,
  benefitImage13,
  individuals,
  businesses,
  communities,
  governments,
} from "../assets";
import {
  BarChart3Icon,
  GlobeIcon,
  ShieldIcon,
  ServerIcon, Cpu, Zap, Shield, Leaf, BarChart,
} from "lucide-react"

export const navigation = [
  {
    id: "0",
    title: "About",
    url: "/about-us",
  },
  {
    id: "1",
    title: "Solutions",
    url: "/solutions",
  },
  {
    id: "2",
    title: "Technology",
    url: "/technology",
  },
  {
    id: "3",
    title: "Operational Design",
    url: "/operations",
  },
  {
    id: "4",
    title: "Contact",
    url: "/contact",
  },
  {
    id: "5",
    title: "Get Started",
    url: "#",
    onlyMobile: true,
  },
  {
    id: "6",
    title: "Get Started",
    url: "/contact",
    onlyMobile: true,
  },
];

// Define dropdown items for Solutions
export const solutionsDropdown = [
  { name: "Enterprise AI", href: "/solutions/enterprise-ai", description: "AI solutions for enterprise operations" },
  { name: "Industry Solutions", href: "/solutions/industry", description: "Tailored solutions for specific industries" },
  { name: "Custom Development", href: "/solutions/custom", description: "Bespoke AI development services" },
];

// Define dropdown items for Technology
export const technologyDropdown = [
  { name: "AI Models", href: "/technology/models", description: "Our proprietary AI models" },
  { name: "Platform", href: "/technology/platform", description: "Our technology platform" },
  { name: "Research", href: "/technology/research", description: "Latest research and development" },
];

export const heroIcons = [homeSmile, file02, searchMd, plusSquare];

export const notificationImages = [notification4, notification3, notification2];

export const companyLogos = [vitf, gre8i];

export const brainwaveServices = [
  "Full data control and ownership",
  "Enhanced security protocols",
  "Transparent operations",
  "Maximized ROI for customers"
];

export const brainwaveServicesIcons = [
  recording03,
  recording01,
  disc02,
  chromecast,
  sliders04,
];

export const roadmap = [
  {
    id: "0",
    title: "Secure and Transparent Systems",
    text: "Gain peace of mind with end-to-end encrypted and decentralized data solutions that ensure complete data sovereignty and transparency.",
    category: "Security",
    imageUrl: security, // You'll need to import this image
    colorful: true,
  },
  {
    id: "1",
    title: "Tailored Insights",
    text: "Leverage AI-driven analytics to extract actionable intelligence from your data, enabling better decision-making and strategic planning.",
    category: "Analytics",
    imageUrl: insights, // You'll need to import this image
  },
  {
    id: "2",
    title: "Operational Efficiency",
    text: "Save costs and improve productivity with intelligent, automated workflows that streamline your business processes.",
    category: "Operations",
    imageUrl: efficiency, // You'll need to import this image
    colorful: true,
  },
  {
    id: "3",
    title: "Sustainable Growth",
    text: "Build scalable systems that deliver long-term financial and environmental benefits, ensuring your business grows responsibly.",
    category: "Sustainability",
    imageUrl: growth, // You'll need to import this image
  },
];

export const collabText =
  "With Intelligent automation and top-notch security, it's the perfect solution for teams looking to work smarter.";

export const collabContent = [
  {
    id: "0",
    title: "Seamless Integration",
    text: collabText,
  },
  {
    id: "1",
    title: "Intelligent automation",
  },
  {
    id: "2",
    title: "Top-notch Security",
  },
];

export const addedValueContent = [
  {
    id: "0",
    title: "Sovereign Data",
    text: "Own your data.  Blockchain transparency ensures secure, verifiable use, while decentralization enhances security and uptime.",
  },
  {
    id: "1",
    title: "Data Security & ROI",
    text: "Secure your data with robust encryption and decentralized storage. Optimize workflows with AI and monetize data through tokenization.",
  },
  {
    id: "2",
    title: "Autonomous  communities",
    text: "Enable smart infrastructure with decentralized funding. AI optimizes resources, and digital IDs empower citizens with equitable access.",
  },
];

export const collabApps = [
  {
    id: "0",
    title: "Figma",
    icon: figma,
    width: 26,
    height: 36,
  },
  {
    id: "1",
    title: "Notion",
    icon: notion,
    width: 34,
    height: 36,
  },
  {
    id: "2",
    title: "Discord",
    icon: discord,
    width: 36,
    height: 28,
  },
  {
    id: "3",
    title: "Slack",
    icon: slack,
    width: 34,
    height: 35,
  },
  {
    id: "4",
    title: "Photoshop",
    icon: photoshop,
    width: 34,
    height: 34,
  },
  {
    id: "5",
    title: "Protopie",
    icon: protopie,
    width: 34,
    height: 34,
  },
  {
    id: "6",
    title: "Framer",
    icon: framer,
    width: 26,
    height: 34,
  },
  {
    id: "7",
    title: "Raindrop",
    icon: raindrop,
    width: 38,
    height: 32,
  },
];

export const pricing = [
  {
    id: "0",
    title: "For People",
    description: "Individual empowerment through data ownership",
    price: null,
    features: [
      "Control and Privacy: Maintain full ownership of personal data with secure and transparent usage tracking",
      "Equitable Participation: Access decentralized platforms that reduce barriers to technology and resources"
    ],
  },
  {
    id: "1",
    title: "For Communities",
    description: "Building resilient and sustainable systems",
    price: null,
    features: [
      "Resilience: Build autonomous systems for energy, data, and resource management",
      "Sustainability: Implement green technologies that promote long-term economic and environmental health"
    ],
  },
  {
    id: "2",
    title: "For Businesses",
    description: "Enhanced efficiency and value generation",
    price: null,
    features: [
      "Operational Efficiency: Streamline operations with decentralized data systems that enhance real-time decision-making",
      "Cost Savings: Eliminate intermediaries, reduce cybersecurity risks, and lower operational overhead",
      "Enhanced ROI: Monetize proprietary data and leverage insights for sustained growth"
    ],
  },
];

export const offerings = [
  {
    id: "0",
    title: "Data Sovereignty for Individuals",
    text: "Empower people to control and monetize their data securely within decentralized frameworks.",
    backgroundUrl: "./card-1.svg",
    iconUrl: benefitIcon3, // Replace with actual icon URL
    imageUrl: benefitImage8
  },
  {
    id: "1",
    title: "Autonomous Communities",
    text: "Connecting People, Interacting Communities, Distributing Abundances with Intelligence",
    backgroundUrl: "./card-5.svg",
    iconUrl: benefitIcon4, // Replace with actual icon URL
    imageUrl: benefitImage4,
    light: true, // If needed
  },
  {
    id: "2",
    title: "Seamless Business Integration",
    text: "Foster real-time collaboration between businesses, partners, and industries.",
    backgroundUrl: "./card-1.svg",
    iconUrl: benefitIcon3, // Replace with actual icon URL
    imageUrl: benefitImage6
  },
  {
    id: "3",
    title: "Decentralized Data Solutions",
    text: "Utilize blockchain and distributed ledgers to ensure data transparency, security, and ownership.",
    backgroundUrl: "./card-5.svg",
    iconUrl: benefitIcon4, // Replace with actual icon URL
    imageUrl: benefitImage7,
    light: true, // If needed
  },
  {
    id: "4",
    title: "AI-Powered Platforms",
    text: "Deliver predictive insights, automate operations, and enhance decision-making across industries.",
    backgroundUrl: "./card-1.svg",
    iconUrl: benefitIcon3, // Replace with actual icon URL
    imageUrl: benefitImage9
  },
  {
    id: "5",
    title: "Ambient and Ubiquitous Systems",
    text: "Integrate context-aware technologies that adapt to user needs naturally.",
    backgroundUrl: "./card-5.svg",
    iconUrl: benefitIcon4, // Replace with actual icon URL
    imageUrl: benefitImage10,
    light: true, // If needed
  },
  {
    id: "6",
    title: "Secure Content Delivery",
    text: "Use decentralized platforms to distribute content, ensuring privacy and reducing costs.",
    backgroundUrl: "./card-1.svg",
    iconUrl: benefitIcon3, // Replace with actual icon URL
    imageUrl: benefitImage11
  },
  {
    id: "7",
    title: "Microgrid Energy Solutions",
    text: "Efficiently distribute renewable energy to power autonomous communities.",
    backgroundUrl: "./card-5.svg",
    iconUrl: benefitIcon4, // Replace with actual icon URL
    imageUrl: benefitImage12,
    light: true, // If needed
  },
  {
    id: "8",
    title: "Data-Driven Resource Allocation",
    text: "Enable governments and businesses to optimize resource distribution using AI analytics.",
    backgroundUrl: "./card-1.svg",
    iconUrl: benefitIcon3, // Replace with actual icon URL
    imageUrl: benefitImage13
  },
];

export const socials = [
  {
    id: "0",
    title: "Discord",
    iconUrl: discordBlack,
    url: "#",
  },
  {
    id: "1",
    title: "Twitter",
    iconUrl: twitter,
    url: "#",
  },
  {
    id: "2",
    title: "Instagram",
    iconUrl: instagram,
    url: "#",
  },
  {
    id: "3",
    title: "Telegram",
    iconUrl: telegram,
    url: "#",
  },
  {
    id: "4",
    title: "Facebook",
    iconUrl: facebook,
    url: "#",
  },
];

// Technology categories
export const technologies = [
  {
    id: "quantum",
    category: "computing",
    title: "Quantum Computing",
    description:
      "Enable advanced data processing, cryptography, and AI-driven algorithms to power decentralized infrastructures.",
    icon: "cpu",
  },
  {
    id: "ambient",
    category: "computing",
    title: "Ubiquitous & Ambient Computing",
    description:
      "Seamless integration of IoT devices, creating adaptive, context-aware environments for optimized user experiences.",
    icon: "network",
  },
  {
    id: "blockchain",
    category: "finance",
    title: "Blockchain Technology",
    description: "Ensuring data integrity and security through decentralized ledgers.",
    icon: "boxes",
  },
  {
    id: "quantum-nlp",
    category: "ai",
    title: "Quantum Natural Language Processing",
    description: "Leveraging quantum computing to enhance data processing capabilities.",
    icon: "brain",
  },
]

// Main sections content
export const sections = [
  {
    id: "individuals",
    title: "Empowering Individuals",
    subtitle: "Data Protection and Monetization",
    description:
      "In today's digital landscape, personal data is a valuable asset. We provide individuals with tools and strategies to protect personal data and monetize their information.",
    points: [
      "Protect Personal Data: Implement robust security measures to safeguard sensitive information.",
      "Monetize Data: Offer platforms that allow users to capitalize on their data, turning privacy into profit.",
    ],
    image: empowering,
  },
  {
    id: "business",
    title: "Innovative Business Solutions",
    subtitle: "Data Monetization and Protection",
    description:
      "Businesses are increasingly reliant on data-driven strategies. Our services include integrated AI solutions and decentralized systems.",
    points: [
      "Integrated AI Solutions: Developing AI-driven technologies that enhance operational efficiency and decision-making.",
      "Decentralized Large Language Models (LLMs): Implementing decentralized AI systems to ensure data sovereignty and security.",
      "Sovereign Data Centers: Establishing data centers that provide businesses with control over their data assets.",
      "Green Energy Microgrids: Integrating sustainable energy solutions to power data infrastructures.",
    ],
    image: innovate,
  },
  {
    id: "communities",
    title: "Building Autonomous Communities",
    subtitle: "Technology and Workforce Development",
    description:
      "We envision communities that are self-sustaining and resilient. Our approach includes innovative workforce development and local initiatives.",
    points: [
      "Innovative Workforce Development: Training programs focused on equipping individuals with skills in emerging technologies.",
      "Zero Mile Zone Initiatives: Promoting local production and consumption to reduce environmental impact and enhance community resilience.",
    ],
    image: autonomous,
  },
  {
    id: "government",
    title: "Government Partnerships",
    subtitle: "Off-Balance-Sheet Infrastructure Funding",
    description:
      "Public infrastructure is the backbone of thriving societies. We assist governments with innovative funding solutions.",
    points: [
      "Infrastructure-as-a-Service (IaaS): Providing funding solutions for essential services such as schools, hospitals, water systems, and parks without adding to public debt.",
      "Sustainable Financing Models: Utilizing creative accounting practices to offer off-balance-sheet funding, enabling the modernization of public infrastructure.",
    ],
    image: government,
  },
]

// Operational capabilities data
export const capabilities = [
  {
    id: "overview",
    title: "Operational Excellence",
    subtitle: "Streamlined Processes & Efficiency",
    description:
      "At Automated Intelligence, we deliver operational excellence through streamlined processes, advanced automation, and data-driven decision making.",
    points: [
      "Process optimization through AI-driven analytics",
      "Automated workflows that reduce manual intervention",
      "Real-time monitoring and predictive maintenance",
      "Continuous improvement methodologies",
    ],
    icon: BarChart3Icon,
    image: opexcellence,
  },
  {
    id: "global",
    title: "Global Operations",
    subtitle: "Worldwide Reach & Local Expertise",
    description:
      "Our global operational footprint enables us to deliver consistent, high-quality services while maintaining local relevance and compliance.",
    points: [
      "24/7 operational capabilities across multiple time zones",
      "Localized service delivery with global standards",
      "Cross-border data management and compliance",
      "Unified operational framework with regional adaptability",
    ],
    icon: GlobeIcon,
    image: global,
  },
  {
    id: "infrastructure",
    title: "Infrastructure Management",
    subtitle: "Resilient & Scalable Systems",
    description:
      "We build and maintain robust infrastructure that scales with your needs while ensuring maximum uptime and performance.",
    points: [
      "Cloud-native architecture with multi-region redundancy",
      "Hybrid infrastructure solutions for legacy integration",
      "Automated scaling based on demand patterns",
      "Comprehensive disaster recovery and business continuity",
    ],
    icon: ServerIcon,
    image: infrastructure,
  },
  {
    id: "security",
    title: "Operational Security",
    subtitle: "Protection at Every Layer",
    description:
      "Security is embedded throughout our operational framework, ensuring data protection, compliance, and risk mitigation.",
    points: [
      "Zero-trust security architecture implementation",
      "Continuous security monitoring and threat detection",
      "Compliance with global security standards and regulations",
      "Regular penetration testing and vulnerability assessments",
    ],
    icon: ShieldIcon,
    image: secure,
  },
]

// Case studies data
export const caseStudies = [
  {
    id: "enterprise",
    title: "Enterprise Transformation",
    client: "Global Financial Institution",
    challenge: "Legacy systems causing operational inefficiencies and high maintenance costs",
    solution: "Implemented a hybrid cloud infrastructure with automated workflows and real-time analytics",
    results: [
      "42% reduction in operational costs",
      "99.99% system uptime achieved",
      "68% faster transaction processing",
    ],
    image: "/placeholder.svg?height=300&width=500",
  },
  {
    id: "government",
    title: "Public Sector Modernization",
    client: "National Government Agency",
    challenge: "Siloed data systems preventing effective service delivery to citizens",
    solution: "Developed an integrated data platform with secure API-driven architecture",
    results: [
      "Reduced service delivery time by 76%",
      "Improved data accuracy by 94%",
      "Enabled 24/7 citizen access to services",
    ],
    image: "/placeholder.svg?height=300&width=500",
  },
  {
    id: "healthcare",
    title: "Healthcare Operations Optimization",
    client: "Regional Hospital Network",
    challenge: "Inefficient resource allocation and patient flow management",
    solution: "Deployed AI-powered predictive analytics for resource planning and patient management",
    results: [
      "Increased patient throughput by 37%",
      "Reduced wait times by 58%",
      "Optimized staff scheduling, saving $4.2M annually",
    ],
    image: "/placeholder.svg?height=300&width=500",
  },
]

// FAQ data
export const faqs = [
  {
    id: "faq1",
    question: "How quickly can you implement operational changes?",
    answer:
      "Our implementation timeline varies based on the complexity of your operations. Typically, we can deploy initial improvements within 4-6 weeks, with full transformation roadmaps extending from 3-12 months. We use an agile approach to deliver value incrementally throughout the process.",
  },
  {
    id: "faq2",
    question: "Do you support legacy system integration?",
    answer:
      "Yes, we specialize in creating hybrid solutions that bridge legacy systems with modern technologies. Our approach minimizes disruption while gradually modernizing your infrastructure, allowing you to preserve existing investments while gaining new capabilities.",
  },
  {
    id: "faq3",
    question: "How do you ensure operational security?",
    answer:
      "Security is embedded in every aspect of our operational framework. We implement zero-trust architecture, continuous monitoring, regular security assessments, and compliance with industry standards like ISO 27001, SOC 2, and GDPR. Our security operations center provides 24/7 threat detection and response.",
  },
  {
    id: "faq4",
    question: "Can you scale operations during peak demand periods?",
    answer:
      "Absolutely. Our infrastructure is designed with auto-scaling capabilities that respond to demand fluctuations. We use predictive analytics to anticipate peak periods and pre-scale resources accordingly. This ensures consistent performance even during unexpected demand surges.",
  },
]

export const applications = [
  {
    title: "Social Media",
    description: "Connect and collaborate with like-minded individuals, build communities, and share value without compromising privacy or control."
  },
  {
    title: "E-commerce",
    description: "Experience a frictionless shopping experience with personalized recommendations, secure checkout, and global marketplaces."
  },
  {
    title: "Customer Service",
    description: "Forget robotic voices and wait times. AiHDV's intelligent assistants provide instant, personalized support, exceeding expectations at every touchpoint."
  },
  {
    title: "Software and Video Integration",
    description: "Break down walls with seamless software and video integration. Access information easily, collaborate effectively, and experience entertainment in a truly immersive way."
  }
];

// Impact sections data
export const impactSections = [
  {
    id: "individuals",
    title: "For Individuals",
    subtitle: "Empowering personal growth and connectivity",
    description: "We believe technology should serve individuals by enhancing quality of life and providing secure, meaningful connections.",
    points: [
      "Secure personal data and enhanced quality of life with intuitive technology",
      "Equitable distribution of resources and information in connected communities",
      "Intelligence integration and personal energy management",
      "Privacy-first technology solutions that put you in control"
    ],
    image: individuals // You'll need to add appropriate images
  },
  {
    id: "businesses",
    title: "For Businesses",
    subtitle: "Optimizing operations through intelligent systems",
    description: "Our business solutions streamline operations, improve customer engagement, and facilitate seamless collaboration.",
    points: [
      "AI-driven analytics to streamline operations and improve customer engagement",
      "Intelligent systems to connect with partners and industries seamlessly",
      "Blockchain integration for secure, transparent transactions",
      "Scalable solutions that grow with your business needs"
    ],
    image: businesses
  },
  {
    id: "communities",
    title: "For Communities",
    subtitle: "Building sustainable, connected ecosystems",
    description: "We help communities become more self-sufficient, connected, and resilient through integrated technology solutions.",
    points: [
      "Self-sufficient systems for energy, water, and waste management",
      "Opportunities through workforce development and technology education",
      "Smart infrastructure that responds to community needs",
      "Digital inclusion initiatives that leave no one behind"
    ],
    image: communities
  },
  {
    id: "governments",
    title: "For Governments",
    subtitle: "Enhancing public services with data-driven solutions",
    description: "Our government-focused solutions optimize resource allocation, improve urban planning, and support vital infrastructure.",
    points: [
      "Optimized urban planning and resource allocation with data-driven solutions",
      "Off-balance-sheet infrastructure projects for schools, hospitals, and public services",
      "Intelligence city integration for improved citizen services",
      "Transparent governance tools for increased public trust"
    ],
    image: governments
  }
]

// Solution categories
export const solutionCategories = [
  {
    id: "connecting",
    title: "Connecting",
    icon: "network",
    description: "Building networks between individuals, communities, and sectors to foster collaboration and growth.",
    examples: [
      "Autonomous Communities",
      "Connected Urban Ecosystems",
      "Collaborative Platforms",
      "Human-Centric Design"
    ]
  },
  {
    id: "interacting",
    title: "Interacting",
    icon: "brain",
    description: "Integrating advanced technologies like ambient computing, AI, and blockchain into intuitive and adaptive systems.",
    examples: [
      "AI-Driven Analytics",
      "Ambient Computing",
      "Blockchain Security",
      "Intuitive Interfaces"
    ]
  },
  {
    id: "distributing",
    title: "Distributing",
    icon: "boxes",
    description: "Ensuring equitable and intelligent distribution of data, energy, and resources to meet diverse needs.",
    examples: [
      "Microgrids for Energy Independence",
      "Decentralized Data Systems",
      "Content Distribution Platforms",
      "Resource Optimization Solutions"
    ]
  }
]

// Revenue streams
export const revenueStreams = [
  {
    title: "Technology Consulting",
    description: "Tailored solutions for businesses, communities, and governments to optimize operations and systems."
  },
  {
    title: "Platform Licensing",
    description: "Access proprietary AI and IoT systems for intelligent automation and analytics."
  },
  {
    title: "Energy and Data Distribution",
    description: "Revenue from microgrids, decentralized data centers, and content-sharing platforms."
  },
  {
    title: "Training and Development",
    description: "Education programs to build skills in AI, IoT, and sustainable technology management."
  }
]

export const govtCardData = [
  {
    icon: Cpu,
    title: "Smart Cities & Connecting",
    description: "AI-powered urban planning, intelligent transportation, and automated security networks."
  },
  {
    icon: Zap,
    title: "Microgrids & Interacting",
    description: "Solar, wind, and decentralized energy storage solutions to power critical services."
  },
  {
    icon: Shield,
    title: "Data Centers & Distributing",
    description: "Secure, sovereign cloud computing for municipal data management."
  },
  {
    icon: Leaf,
    title: "Water & Waste Management",
    description: "AI-driven distribution, smart irrigation, and waste-to-energy solutions."
  },
  {
    icon: BarChart,
    title: "EV Charging & Smart Transportation",
    description: "Fleet electrification and sustainable mobility solutions."
  }
];