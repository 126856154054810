import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import ButtonGradient from "./assets/svg/ButtonGradient";


const HomePage = lazy(() => import("./pages/HomePage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const SolutionsPage = lazy(() => import("./pages/SolutionsPage"));
const TechnologyPage = lazy(() => import("./pages/TechnologyPage"));
const OperationalPage = lazy(() => import("./pages/OperationalPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));

const App = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <Suspense
          fallback={
            <div className="flex justify-center items-center w-full h-[100vh] bg-slate-100">
              <div className="custom-loader"></div>
            </div>
          }
        >
          <Layout>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about-us" element={<AboutPage />} />
              <Route path="/solutions" element={<SolutionsPage />} />
              <Route path="/technology" element={<TechnologyPage />} />
              <Route path="/operations" element={<OperationalPage />} />
              <Route path="/contact" element={<ContactPage />} />
              {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
          </Layout>
        </Suspense>
      </div>
      <ButtonGradient />
    </>
  );
};

export default App;
