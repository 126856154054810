import SectionSvg from "../assets/svg/SectionSvg";
import { useTheme } from "../context/ThemeProvider";

const Section = ({ className, id, crosses, crossesOffset, customPaddings, children }) => {
  const { theme } = useTheme();

  return (
    <div
      id={id}
      className={`
        relative transition-all duration-500 
        ${customPaddings || `py-10 lg:py-16 xl:py-20 ${crosses ? "lg:py-32 xl:py-40" : ""}`} 
        ${theme === "dark" ? "bg-gray-900 text-gray-200" : "bg-white text-gray-900"} 
        ${className || ""}
      `}
    >
      {children}

      {/* Left and Right Borders */}
      <div
        className={`hidden absolute top-0 left-5 w-0.25 h-full pointer-events-none md:block lg:left-7.5 xl:left-10 transition-all duration-500 ${
          theme === "dark" ? "bg-gray-700" : "bg-gray-300"
        }`}
      />
      <div
        className={`hidden absolute top-0 right-5 w-0.25 h-full pointer-events-none md:block lg:right-7.5 xl:right-10 transition-all duration-500 ${
          theme === "dark" ? "bg-gray-700" : "bg-gray-300"
        }`}
      />

      {/* Crosses */}
      {crosses && (
        <>
          <div
            className={`hidden absolute top-0 left-7.5 right-7.5 h-0.25 pointer-events-none lg:block xl:left-10 xl:right-10 transition-all duration-500 ${
              theme === "dark" ? "bg-gray-700" : "bg-gray-300"
            }`}
            style={{ marginTop: crossesOffset || 0 }}
          />
          <SectionSvg crossesOffset={crossesOffset} />
        </>
      )}
    </div>
  );
};

export default Section;
